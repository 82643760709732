// extracted by mini-css-extract-plugin
export var orderCloseButton = "order-module--order-close-button--kSkTJ";
export var orderContainer = "order-module--order-container--UvBrb";
export var orderForm = "order-module--order-form--1db06";
export var orderFormButton = "order-module--order-form-button--onsFV";
export var orderFormInput = "order-module--order-form-input--1ou5o";
export var orderFormNotice = "order-module--order-form-notice--JydMg";
export var orderFormPlan = "order-module--order-form-plan--R15mZ";
export var orderFormPlanLabel = "order-module--order-form-plan-label--RHWXQ";
export var orderFormPlanText = "order-module--order-form-plan-text--n5Oxz";
export var orderFormTextArea = "order-module--order-form-text-area--vRno7";
export var orderTitleEn = "order-module--order-title-en--W74N8";
export var orderTitleJp = "order-module--order-title-jp--B2iTQ";