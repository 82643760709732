import * as React from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'
import { useLocation } from '@gatsbyjs/reach-router'
import * as styles from '../styles/order.module.css'

const Order = ({ location }) => {
  const { state = {} } = location
  const { plan } = state

  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    fbAppId,
  } = site.siteMetadata

  return (
    <div className={styles.orderContainer}>
      <Helmet title="お申し込みフォーム" titleTemplate={titleTemplate} defer={false}>
        <html lang="ja"/>
        <meta name="robots" content="index"/>
        <link rel="canonical" href={`${siteUrl}${pathname}`}/>

        <meta name="description" content={`お申し込みフォーム${defaultDescription}`}/>
        <meta name="image" content={defaultImage}/>

        <meta property="og:locale" content="ja_JP"/>
        <meta property="og:site_name" content={defaultTitle}/>
        <meta property="og:url" content={`${siteUrl}${pathname}`}/>
        <meta property="og:title" content="お申し込みフォーム"/>
        <meta property="og:description" content={`お申し込みフォーム${defaultDescription}`}/>
        <meta property="og:image" content={defaultImage}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content={twitterUsername}/>
        <meta name="twitter:creator" content={twitterUsername}/>
        <meta name="twitter:title" content="お申し込みフォーム"/>
        <meta name="twitter:description" content={`お申し込みフォーム${defaultDescription}`}/>
        <meta name="twitter:image" content={defaultImage}/>
        <meta property="fb:app_id" content={fbAppId}/>
      </Helmet>
      <h1 className={styles.orderTitleEn}>Order Form</h1>
      <h2 className={styles.orderTitleJp}>お申し込みフォーム</h2>
      <button onClick={() => navigate(-1)} className={styles.orderCloseButton}>
        <StaticImage
          alt="back to previous page"
          src="../images/icon-close.svg"
        />
      </button>
      <form action="/thank-you" className={styles.orderForm} name="order" method="POST" data-netlify="true" netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="order" />
        <p><input required className={styles.orderFormInput} type="text" name="name" placeholder="お名前"/></p>
        <p><input required className={styles.orderFormInput} type="email" name="email" placeholder="メールアドレス"/></p>
        <div className={styles.orderFormPlan}>
          <p className={styles.orderFormPlanText}>ご希望のプラン</p>
          <div>
            <input type="radio" id="school" name="plan" value="school"
              checked={plan === "school" ? true : false}
            />
            <label className={styles.orderFormPlanLabel} for="school">クリエイティブスクール</label>
          </div>
          <div>
            <input type="radio" id="production" name="plan" value="production"
              checked={plan === "production" ? true : false}
            />
            <label className={styles.orderFormPlanLabel} for="production">クリエイティブ制作</label>
          </div>
          <div>
            <input type="radio" id="coaching" name="plan" value="coaching"
              checked={plan === "coaching" ? true : false}
            />
            <label className={styles.orderFormPlanLabel} for="coaching">ライフコーチング</label>
          </div>
        </div>
        <p><textarea className={styles.orderFormTextArea} name="message" placeholder="ご希望のコース、ご要望、ご質問など"></textarea></p>
        <p className={styles.orderFormNotice}>
          ＊入力いただいたメールアドレスへご返信させていただきますので、お間違いのないようご確認ください。
        </p>
        <p className={styles.orderFormNotice}>
          ＊24時間以内に今後の進め方についてメールにてご連絡させていただきます。
        </p>
        <p className={styles.orderFormNotice}>
          ＊返信メールが<strong>迷惑メールフォルダ</strong>へ振り分けられてしまうことがありますので、ご注意ください。
        </p>
        <p>
          <button className={styles.orderFormButton} type="submit">上記の内容で送信する</button>
        </p>
      </form>
    </div>
  )
}

export default Order

const query = graphql`
  query order {
    site {
      siteMetadata {
        defaultTitle
        titleTemplate
        defaultDescription
        siteUrl
        defaultImage
        twitterUsername
        fbAppId
      }
    }
  }
`